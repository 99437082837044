import React, { Suspense, lazy,useEffect,useContext } from "react";
import { BrowserRouter, Route,Switch } from 'react-router-dom';
import Loader from './component/loader/loader';
import {ToastContext} from './context/toastContext';
const AsyncHomePage    = lazy(() => import('./pages/HomePage'));
const AddPropertyDetails    = lazy(() => import('./pages/AddPropertyDetailsPage'));
const AddPartyDetails    = lazy(() => import('./pages/AddPartyDetailsPage'));
const EscrowDetailsPage    = lazy(() => import('./pages/AddEscrowDetailsPage'));
const Unauthorization    = lazy(() => import('./component/unauthorization/unauthorization'));
const PrivateRoute    = lazy(() => import('./component/privateRoute/privateRoute'));
const PreviewPage    = lazy(() => import('./pages/PreviewPage'));
const MyAgreementPage    = lazy(() => import('./pages/myAgreementPage'));
const PdfDownload    = lazy(() => import('./pages/PdfDownload'));
const  TerminationPdf   = lazy(() => import('./pages/TerminationPdf'));
const ShowPdf    = lazy(() => import('./pages/ShowPdf'));


function App() {
  const {toast,clearToast}=useContext(ToastContext);
  useEffect(()=>{
    function handleClickOutside(){
      if(toast.type !== ""){
        clearToast();
      }
    }
    document.addEventListener('mousedown',handleClickOutside)
  },[toast])
  return (
    <BrowserRouter>
    <Suspense fallback={<Loader/>}>
       <Switch>
        <Route path = '/' component = {AsyncHomePage} exact/>
        <PrivateRoute path = '/AddPropertyDetails/:id?' component = {AddPropertyDetails} exact />
        <PrivateRoute path = '/AddPartyDetails/:id' component = {AddPartyDetails} exact />
        <PrivateRoute  path ='/AddEscrowDetails/:id' component = {EscrowDetailsPage} exact />
        <PrivateRoute  path ='/Previewpage/:id/:user?' component = {PreviewPage}  exact/>
        <PrivateRoute  path ='/myAgreement' component = {MyAgreementPage}  exact/>
        <Route  path='/escrowpdf/:type/:id/:stamp' component = {PdfDownload}  exact />
        <Route  path="/document/:name" component = {ShowPdf} exact />
        <Route component = {Unauthorization} path = '*' exact/>
       </Switch>
       </Suspense>
      </BrowserRouter>
  );
}

export default App;
