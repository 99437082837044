import React,{createContext,useReducer} from 'react'
import {AgreementReducer} from '../reducer/agreementReducer';
export const AgreementContext = createContext();

const AgreementProvider = ({children}) => {
    const  [agreements, dispatch] = useReducer(AgreementReducer, {});
    // console.log(agreements)
    return (
        <AgreementContext.Provider value={{agreements, dispatch}}>
            {children}
        </AgreementContext.Provider>
    )
}
export default AgreementProvider;