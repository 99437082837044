import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import PartiesProvider from './context/partiesContext';
import AgreementProvider from './context/agreementContext';
import EscrowProvider from './context/escrowContext';
import ToastProvider from './context/toastContext';

ReactDOM.render(
  <AgreementProvider>
  <ToastProvider>
    <PartiesProvider >
      <EscrowProvider>
        <App />
      </EscrowProvider>
    </PartiesProvider>
  </ToastProvider>
  </AgreementProvider>,
  document.getElementById('root')
);