import React from 'react'

const Loader = ({loading}) => {
    return (
        <>{
            loading?<div className="loader">
            <img src="../../assets/images/loader.gif" alt="loader" />
       </div> :''
        } 
        </>
    )
}

export default Loader;
