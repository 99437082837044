

export const AgreementReducer=( state, action)=>{
    switch (action.type){
        case 'ADD_AGREEMENT':
            return {...action.payload}
        case 'ADD_PARTY':
            let parties=[]
            if(state.escrowParties){
              parties=[...state.escrowParties,action.payload]
            }else{
              parties=[action.payload]
            }
            return {...state,escrowParties:parties }
        case 'EDIT_PARTY':
            const uniqueId=action.payload.id;
             const Parties=state.escrowParties;
            for(let i=0;i<Parties.length;i++){
                if(Parties[i].id===uniqueId){
                    Parties[i]=action.payload;
                }
            }
            return{...state,escrowParties: Parties}
        case 'REMOVE_PARTY':
            const partiesArray=state.escrowParties.filter(x=>x.id !== action.payload)
            // console.log(partiesArray)
            return{...state,escrowParties: partiesArray}
        default:
              return state; 
    }
   
}