export const EscrowReducer = ( state, action)=>{
    switch (action.type){
        case 'ADD_ESCROW':
            return {...action.payload}
        case 'SHOW_PREVIEW':
            return {...action.payload}

        // case 'ADD_ESCROW_DETAIL':
        //     return{...state, action.currency,action.escrowAmount}
        default:
            return {...state}
    }
}