import React,{createContext,useReducer} from 'react'
import {PartiesReducer} from '../reducer/partiesReducer';
export const PartiesContext = createContext();

const PartiesProvider = ({children}) => {
    const  [parties, dispatch] = useReducer(PartiesReducer, []);
    return (
        <PartiesContext.Provider value={{parties, dispatch}}>
            {children}
        </PartiesContext.Provider>
    )
}
export default PartiesProvider;