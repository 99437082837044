import React,{createContext,useReducer} from 'react'
import {EscrowReducer} from '../reducer/escrowReducer';
export const EscrowContext = createContext();

const EscrowProvider = ({children}) => {
    const  [escrow, dispatch] = useReducer(EscrowReducer,{
        currency:'INR',
        escrowAmount:'',
        expirationDate:null,
        settlementParties:[
            {
                settlementParty:'',
                partyAmount:''
            }
        ],
        refundParties:[{
            refundParty:'',
            refundAmount:''
        }],
    }      
);
    return (
        <EscrowContext.Provider value={{escrow, dispatch}}>
            {children}
        </EscrowContext.Provider>
    )
}
export default EscrowProvider;
