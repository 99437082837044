import React,{createContext,useState} from 'react'
export const ToastContext = createContext();

const ToastProvider = ({children}) => {
    const  [toast, showToastData] = useState({
        type:"",
        message:""
    });
    const showingToast = (data)=>{
        showToastData(data)
    }
    const clearToast = () =>{
        showToastData({
            type:"",
            message:""
        })
    }
    return (
        <ToastContext.Provider value={{toast, showingToast,clearToast}}>
            {children}
        </ToastContext.Provider>
    )
}
export default ToastProvider;