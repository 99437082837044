

export const PartiesReducer = ( state, action)=>{
    switch (action.type){
        case 'EDIT_PARTY':
            const uniqueId=action.payload.id;
            // const newData = [];
           for(let i=0;i<state.length;i++){
                if(state[i].id===uniqueId){
                    state[i]=action.payload;
                }
           }
           return[...state]
        case 'PARTY_PREVIEW':
            return [...action.payload]
        case 'REMOVE_PARTY':
            return state.filter(x=>x.id !== action.payload)

            
    }
    return state;
}